.App {
    text-align: center;
}

body {
    background-image: url('https://i0.statig.com.br/ig/carnaval-2022/bg-carnaval-2022.jpg');
    background-position: center;
    background-size: 1425px;
    align-items: center;
    justify-content: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.full-page {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    height: 100%;
}

.header-odia {
    height: 110px;
    box-shadow: 0px 0px 5px #c1baba;
    background-color: #ffdd00;
}

.row-header-odia {
    height: 80px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
}

.go-home a:hover {
    text-decoration: none;
    color: #838383;
    font-weight: 500;
    text-shadow: 0px 0px 0.3px #c1c1c1;
    white-space: nowrap;
}

.go-home a {
    white-space: nowrap;
    text-decoration: none;
    color: #121010;
    font-weight: 500;
    text-shadow: 0px 0px 0.3px #c1c1c1;
}

.m-g {
    margin-top: 30px!important;
}

.go-home {
    display: inline;
    padding: 10px;
}

@media screen and (min-width:992px) {
    .menu-toogle-h {
        display: none!important;
    }
    .ig-picture {
        width: 248px;
        z-index: 999;
        margin: 20px;
    }
    .ig-picture.odia-picture{
        width: 550px;
    }
    .in {
        margin: 120px auto;
        max-width: 1200px;
        padding: 5px;
    }
}

@media screen and (max-width:991px) {
    .items-menu {
        display: none!important;
    }
    @media (prefers-reduced-motion: no-preference) {
        .App-logo {
            animation: App-logo-spin infinite 20s linear;
        }
    }
    .App-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        
    }
    .menu-toogle-h {
        display: block!important;
    }
    .ig-picture {
        display: none;
    }
    .in {
        max-width: 1200px;
        padding: 5px;
        margin: 120px auto;
    }
}

.items-menu {
    min-width: 280px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-folia {
    margin: 0 auto;
}

.logo-ig img {
    width: 90px;
    height: auto;
}

@media screen and (max-width:991px) {
    /* .logo-folia img {
    margin-left: 49.6%;
    } */
    .btn {
        right: 59px;
        position: absolute;
        top: 27px;
    }
    .btn-rj {
        position: absolute;
        top: 27px;
        margin-left: -97px;
    }
}

.logo-folia img {
    width: 200px;
    height: auto;
}

.social-buttons {
    height: 85px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
    align-items: center;
}

.social-buttons__button {
    margin: 10px 5px 0;
}

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 40px;
    height: 40px;
    text-decoration: none;
    border-radius: 100%;
    background: #fff;
    text-align: center;
}

.social-button::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.3s;
}

.social-button:focus,
.social-button:hover {
    color: #fff;
}

.social-button:focus::after,
.social-button:hover::after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: calc(-50% - 1px);
}

.social-button i,
.social-button img .social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
}

.social-button img {
    font-size: 20px;
}

.social-button i {
    font-size: 20px;
}

.social-button svg {
    height: 40%;
    width: 40%;
}

.social-button--mail {
    color: #0072c6;
}

.social-button--mail::after {
    background: #0072c6;
}

.social-button--facebook {
    color: #3b5999;
}

.social-button--facebook::after {
    background: #3b5999;
}

.social-button--twitter {
    color: #0077b5;
}

.social-button--twitter::after {
    background: #0077b5;
}

.social-button--github {
    color: #6e5494;
}

.social-button--github::after {
    background: #6e5494;
}

.social-button--codepen {
    color: #212121;
}

.social-button--codepen::after {
    background: #212121;
}

.social-button--youtube {
    color: #b90000;
}

.social-button--youtube::after {
    background: #b90000;
}

.social-button--instagram {
    color: #d918af;
}

.social-button--instagram::after {
    background: #d918af;
}

.social-button--twitter {
    color: #fff;
}

.social-button--twitter::after {
    background: #000;
}

.social-button--npmjs {
    color: #c12127;
}

.social-button--npmjs::after {
    background: #c12127;
}


/*menu toogle*/

.menu-toogle-h {
    text-align: center;
    padding: 10px 0px;
    position: absolute;
    top: 0;
}

.hamburger {
    position: relative;
    width: 70px;
    height: 70px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px;
}

.hamburger:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background: #009ee0;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #003b8e, #009ee0);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #003b8e, #009ee0);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    transform: rotate(0deg);
    transition: all 0.4s cubic-bezier(0.54, -0.1, 0.57, 0.57);
}

.hamburger .hamburger-input {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.hamburger .hamburger-line {
    width: 100%;
    background: #fff;
    height: 2px;
    display: block;
    border-radius: 6px;
    transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
    position: relative;
}

.hamburger .hamburger-line.first,
.hamburger .hamburger-line.third {
    width: 50%;
}

.hamburger .hamburger-line.third {
    margin-left: 50%;
    transform-origin: left;
}

.menu-button-wrapper {
    position: relative;
    display: inline-block;
}

.menu-button-wrapper .item-list {
    position: absolute;
    top: 90px;
    transform: translateX(-50%) scale(0);
    transform-origin: center;
    transition: all 0.4s cubic-bezier(0.54, -0.1, 0.57, 0.57);
    background-color: #303242;
    color: #fff;
    width: 270px;
    left: 120px;
    padding: 15px 0;
    text-align: left;
    border-radius: 100px;
    font-weight: 300;
    opacity: 0;
    user-select: none;
}

.menu-button-wrapper .item-list div {
    padding: 10px 30px;
    /* cursor: pointer; */
    text-decoration: none;
    color: #fff!important;
}

.menu-button-wrapper .item-list div .a-link {
    cursor: pointer;
    text-decoration: none;
    color: #fff!important;
}

.menu-button-wrapper .menu-button {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}

.menu-button:checked~.item-list {
    transform: translateX(-50%) scale(1);
    border-radius: 20px;
    opacity: 1;
    user-select: auto;
    z-index: 9999999;
}

.menu-button:checked~.icon-wrapper .hamburger-line.second {
    transform: rotate(-45deg);
}

.menu-button:checked~.icon-wrapper .hamburger-line.first {
    transform: translate(2px, 8px) rotate(-135deg);
}

.menu-button:checked~.icon-wrapper .hamburger-line.third {
    transform: translate(11px, -3px) rotate(-135deg);
}

.menu-button:checked~.icon-wrapper .hamburger:before {
    transform: rotate(45deg);
}

.signature-wrapper {
    margin-top: 150px;
    margin-bottom: 100px;
    text-align: center;
}

.signature-wrapper .signature-title {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: normal;
    color: #444;
}

.signature-wrapper .signature-link {
    color: #444;
    display: inline-block;
    margin: 20px 0 10px;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-decoration: none;
    border-bottom: solid 2px #444;
}


/* tabelas */

.title-quesito {
    font-size: 24px;
    padding: 5px 5px 5px 21px;
    /* border: 1px solid #ccc; */
    background-color: #EBEBEB;
    border-radius: 8px 8px 0 0;
    color: white;
    text-shadow: 1px 1px 2px #939393;
    text-align: left;
    text-transform: uppercase;
    font-weight: 500;
    margin: 20px 0 0 0px;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic');
.btn {
    /* display: block; */
    font-weight: 700;
    position: absolute;
    background-color: white;
    width: 133px;
    top: 13px;
    padding: 13px 25px;
    text-decoration: none;
    color: #333;
    border-radius: 50px;
    /* border: 1px solid white; */
    transition: all .2s;
    z-index: 1;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.btn:hover {
    transform: translateY(-5px)
    /* scale(1.05,1.05) */
    ;
    background-color: rgba(#fff, 1);
    border: none;
}

.btn:active {
    transform: translateY(0px)
    /* scale(.95,.95) */
    ;
}

.btn-rj {
    /* display: block; */
    font-weight: 700;
    position: absolute;
    background-color: white;
    width: 133px;
    top: 13px;
    padding: 13px 25px;
    text-decoration: none;
    color: #333;
    border-radius: 50px;
    /* border: 1px solid white; */
    transition: all .2s;
    z-index: 1;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.btn-rj:hover {
    transform: translateY(-5px)
    /* scale(1.05,1.05) */
    ;
    background-color: rgba(#fff, 1);
    border: none;
}

.btn-rj:active {
    transform: translateY(0px)
    /* scale(.95,.95) */
    ;
}

.btn_live {
    padding-right: 22px;
}

span.live-icon {
    display: inline-block;
    position: relative;
    top: calc(50% - 5px);
    background-color: red;
    width: 10px;
    height: 10px;
    margin-left: 20px;
    border: 1px solid rgba(black, .1);
    border-radius: 50%;
    z-index: 1;
}

span.live-icon::before {
    content: '';
    display: block;
    position: absolute;
    background-color: red;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: live 2s ease-in-out infinite;
    z-index: 999999;
}

@keyframes live {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(3.5, 3.5);
        background-color: rgba(red, 0);
    }
}

@media only screen and (max-width: 500px) {
    .btn {
        width: 77%;
        top: 180px;
    }
    .btn-rj {
        width: 77%;
        top: 180px;
    }
    span.live-icon {
        position: absolute;
    }
}

@media only screen and (min-width: 992px) {
    .btn {
        right: 32%;
        top: 21px;
    }
    .btn-rj {
        margin-left: -72px;
        top: 21px;
    }
}

.logo-rj {
    margin-top: 7px;
}

@media screen and (max-width:500px) {
    .logo-rj {
        margin-left: 75px;
    }
    .btn-rj {
        margin-left: -83%;
    }
}

@media screen and (max-width:576px) {
    .ajuste-title-sp {
        margin-top: 122px!important;
    }
    .title-carnaval {
        font-size: 26px;
        letter-spacing: 11px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: gold;
        border-radius: 4px;
        color: #ffffff;
        text-shadow: 1px 1px 2px #db017a;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
        text-align: center;
        box-shadow: 0px 0px 8px #c9c9c9;
    }
    .title-quesito-completo {
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #06b95d;
        border-radius: 8px 8px 0 0;
        color: #fff;
        text-shadow: 1px 1px 3px #101010;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-yellow {
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #fbca09;
        border-radius: 8px 8px 0 0;
        color: #000;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-blue {
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #0087cc;
        border-radius: 8px 8px 0 0;
        color: white;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-completa {
        text-align: left!important;
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #008340;
        border-radius: 8px 8px 0 0;
        color: white;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
}

.table>thead {
    background: #0044a9;
}

@media screen and (min-width:577px) {
    .title-carnaval {
        font-size: 32px;
        letter-spacing: 11px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: gold;
        border-radius: 4px;
        color: #ffffff;
        text-shadow: 1px 1px 2px #db017a;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
        text-align: center;
        box-shadow: 0px 0px 8px #c9c9c9;
    }
    .title-quesito-completo {
        font-size: 24px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #06b95d;
        border-radius: 8px 8px 0 0;
        color: #fff;
        text-shadow: 1px 1px 3px #101010;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-yellow {
        font-size: 24px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #fbca09;
        border-radius: 8px 8px 0 0;
        color: #000;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .App-link {
        color: #61dafb;
    }
    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .full-page {
        width: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        height: 100%;
    }
    .header-odia {
        height: 90px;
        box-shadow: 0px 0px 5px #c1baba;
        background-color: #ffdd00;
    }
    .row-header-odia {
        height: 80px;
        max-width: 1200px;
        margin: 0 auto;
        padding: 10px;
    }
    .go-home a:hover {
        text-decoration: none;
        color: #838383;
        font-weight: 500;
        text-shadow: 0px 0px 0.3px #c1c1c1;
        white-space: nowrap;
    }
    .go-home a {
        white-space: nowrap;
        text-decoration: none;
        color: #121010;
        font-weight: 500;
        text-shadow: 0px 0px 0.3px #c1c1c1;
    }
    .go-home {
        display: inline;
        padding: 10px;
    }
}

@media screen and (min-width:992px) {
    .menu-toogle-h {
        display: none!important;
    }
}

@media screen and (max-width:991px) {
    .items-menu {
        display: none!important;
    }
    .none-desk {
        display: none!important;
    }
    .menu-toogle-h {
        display: block!important;
    }
}

.items-menu {
    min-width: 280px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-folia {
    margin: 0 auto;
}

.logo-folia img {
    width: 200px;
    height: auto;
}

.in {
    margin: 130px auto 200px auto;
    max-width: 1200px;
    padding: 5px;
}

.ig {
    margin: 16px auto 200px auto;
    max-width: 1200px;
    padding: 5px;
}

.social-buttons {
    height: 85px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
    align-items: center;
}

.social-buttons__button {
    margin: 10px 5px 0;
}

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 40px;
    height: 40px;
    text-decoration: none;
    border-radius: 100%;
    background: #fff;
    text-align: center;
}

.social-button::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.3s;
}

.social-button:focus,
.social-button:hover {
    color: #fff;
}

.social-button:focus::after,
.social-button:hover::after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: calc(-50% - 1px);
}

.social-button i,
.social-button img .social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
}

.social-button img {
    font-size: 20px;
}

.social-button i {
    font-size: 20px;
}

.social-button svg {
    height: 60%;
    width: 60%;
    z-index: 1;
    fill: #000;
}
.social-button:hover svg {
    fill: #fff;
}
.social-button--mail {
    color: #0072c6;
}

.social-button--mail::after {
    background: #0072c6;
}

.social-button--facebook {
    color: #3b5999;
}

.social-button--facebook::after {
    background: #3b5999;
}

.social-button--twitter {
    color: #fff;
}

.social-button--twitter::after {
    background: #000;
}

.social-button--github {
    color: #6e5494;
}

.social-button--github::after {
    background: #6e5494;
}

.social-button--codepen {
    color: #212121;
}

.social-button--codepen::after {
    background: #212121;
}

.social-button--youtube {
    color: #b90000;
}

.social-button--youtube::after {
    background: #b90000;
}

.social-button--instagram {
    color: #d918af;
}

.social-button--instagram::after {
    background: #d918af;
}

.social-button--twitter {
    color: #fff;
}

.social-button--twitter::after {
    background: #000;
}

.social-button--npmjs {
    color: #c12127;
}

.social-button--npmjs::after {
    background: #c12127;
}


/*menu toogle*/

.menu-toogle-h {
    text-align: center;
    padding: 10px 0px;
    position: absolute;
    top: 0;
}

.hamburger {
    position: relative;
    width: 70px;
    height: 70px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px;
}

.hamburger:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background: #009ee0;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #003b8e, #009ee0);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #003b8e, #009ee0);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    transform: rotate(0deg);
    transition: all 0.4s cubic-bezier(0.54, -0.1, 0.57, 0.57);
}

.hamburger .hamburger-input {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.hamburger .hamburger-line {
    width: 100%;
    background: #fff;
    height: 2px;
    display: block;
    border-radius: 6px;
    transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
    position: relative;
}

.hamburger .hamburger-line.first,
.hamburger .hamburger-line.third {
    width: 50%;
}

.hamburger .hamburger-line.third {
    margin-left: 50%;
    transform-origin: left;
}

.menu-button-wrapper {
    position: relative;
    display: inline-block;
}

.menu-button-wrapper .item-list {
    position: absolute;
    top: 90px;
    transform: translateX(-50%) scale(0);
    transform-origin: center;
    transition: all 0.4s cubic-bezier(0.54, -0.1, 0.57, 0.57);
    background-color: #303242;
    color: #fff;
    width: 270px;
    left: 120px;
    padding: 15px 0;
    text-align: left;
    border-radius: 100px;
    font-weight: 300;
    opacity: 0;
    user-select: none;
}

.menu-button-wrapper .item-list div {
    padding: 10px 30px;
    /* cursor: pointer; */
    text-decoration: none;
    color: #fff!important;
}

.menu-button-wrapper .item-list div .a-link {
    cursor: pointer;
    text-decoration: none;
    color: #fff!important;
}

.menu-button-wrapper .menu-button {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}

.menu-button:checked~.item-list {
    transform: translateX(-50%) scale(1);
    border-radius: 20px;
    opacity: 1;
    user-select: auto;
    z-index: 9999999;
}

.menu-button:checked~.icon-wrapper .hamburger-line.second {
    transform: rotate(-45deg);
}

.menu-button:checked~.icon-wrapper .hamburger-line.first {
    transform: translate(2px, 8px) rotate(-135deg);
}

.menu-button:checked~.icon-wrapper .hamburger-line.third {
    transform: translate(11px, -3px) rotate(-135deg);
}

.menu-button:checked~.icon-wrapper .hamburger:before {
    transform: rotate(45deg);
}

.signature-wrapper {
    margin-top: 150px;
    margin-bottom: 100px;
    text-align: center;
}

.signature-wrapper .signature-title {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: normal;
    color: #444;
}

.signature-wrapper .signature-link {
    color: #444;
    display: inline-block;
    margin: 20px 0 10px;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-decoration: none;
    border-bottom: solid 2px #444;
}


/* tabelas */

@media screen and (max-width:576px) {
    .title-quesito-completo {
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #06b95d;
        border-radius: 8px 8px 0 0;
        color: #fff;
        text-shadow: 1px 1px 3px #101010;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-yellow {
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #fbca09;
        border-radius: 8px 8px 0 0;
        color: #000;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-blue {
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #008340;
        border-radius: 8px 8px 0 0;
        color: white;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-completa {
        text-align: left!important;
        width: 100%;
        font-size: 18px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #008340;
        border-radius: 8px 8px 0 0;
        color: white;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px auto 0 auto;
    }
    /* .ig-c {
        display: none!important;
    } */
}

@media screen and (min-width:577px) {
    .title-quesito-completo {
        font-size: 24px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #06b95d;
        border-radius: 8px 8px 0 0;
        color: #fff;
        text-shadow: 1px 1px 3px #101010;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-yellow {
        font-size: 24px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #fbca09;
        border-radius: 8px 8px 0 0;
        color: #000;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-blue {
        font-size: 24px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #008340;
        border-radius: 8px 8px 0 0;
        color: white;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0 0 0px;
    }
    .title-quesito-completa {
        text-align: center!important;
        width: 100%;
        font-size: 24px;
        padding: 5px 5px 5px 21px;
        /* border: 1px solid #ccc; */
        background-color: #008340;
        border-radius: 8px 8px 0 0;
        color: white;
        text-shadow: 1px 1px 2px #939393;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px auto 0 auto;
    }
}

.vencedora {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: flex;
    margin: 90px 0px 0px 0px;
}

.titulo-vencedora {}

.centralizar {
    width: 100%;
    margin: 0 auto;
    background-color: white;
    padding: 26px 0px;
}

.ig-c {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.escola-campea {
    margin: 20px 0;
}

@media screen and (min-width:1201px) {
    .img-campea {
        width: 1190px;
    }
    .titulo-escola-campea {
        top: -210px;
        font-size: 85px!important;
    }
}

@media screen and (min-width:992px) and (max-width:1200px) {
    .img-campea {
        width: 980px;
    }
    .titulo-escola-campea {
        top: -180px;
        font-size: 85px!important;
    }
}

@media screen and (min-width:777px) and (max-width:991px) {
    .img-campea {
        width: 764px;
    }
    .titulo-escola-campea {
        top: -70px;
        font-size: 35px!important;
    }
}

@media screen and (min-width:577px) and (max-width:776px) {
    .img-campea {
        width: 570px;
    }
    .titulo-escola-campea {
        top: -56px;
        font-size: 1em!important;
    }
}

@media screen and (min-width:421px) and (max-width:576px) {
    .img-campea {
        width: 410px;
    }
    .titulo-escola-campea {
        top: -39px;
        font-size: 0.7em!important;
    }
}

@media screen and (min-width:381px) and (max-width:420px) {
    .img-campea {
        width: 370px;
    }
    .titulo-escola-campea {
        top: -39px;
        font-size: 0.7em!important;
    }
}

@media screen and (min-width:341px) and (max-width:380px) {
    .img-campea {
        width: 331px;
    }
    .titulo-escola-campea {
        top: -33px;
        font-size: 0.6em!important;
    }
}

@media screen and (min-width:200px) and (max-width:340px) {
    .img-campea {
        width: 320px;
    }
    .titulo-escola-campea {
        top: -30px;
        font-size: 0.6em!important;
    }
}

.img-campea {
    border-radius: 18px;
    box-shadow: 0px 0px 5px #9b9b9b;
}

.titulo-escola-campea {
    color: #9affc6;
    position: relative;
    /* top: -121px;
    font-size: 2.8em; */
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 3px #004e9c;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer {
    display: none!important;
}

.MuiDataGrid-root .MuiDataGrid-cell--textRight {
    text-align: center!important;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    /* text-align: center!important; */
}

.MuiDataGrid-cell {
    /* text-align: center; */
}

.MuiDataGrid-cell .MuiDataGrid-cell--textRight .MuiDataGrid-cell--editable {
    text-align: center!important;
}

.MuiDataGrid-cell .MuiDataGrid-cell--textLeft {
    text-align: center!important;
}

.MuiBox-root {
    height: auto!important;
}

.MuiDataGrid-renderingZone {
    max-height: none !important
}

.MuiDataGrid-cell {
    /* line-height: unset !important; */
    font-size: 18px;
    max-height: none !important;
    /* white-space: normal; */
}

.MuiDataGrid-row {
    max-height: none !important
}


/* TABELA BOOTSTRAP */

.demo {
    font-family: 'Noto Sans', sans-serif;
}

.panel {
    /* background: linear-gradient(to right, #2980b9, #2c3e50); */
    background: linear-gradient(to right, #fff, #fff);
    padding: 0;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
}

.panel .panel-heading {
    padding: 20px 15px;
    border-radius: 10px 10px 0 0;
    margin: 0;
}

.panel .panel-heading .title {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 40px;
    margin: 0;
}

.panel .panel-heading .btn-rj {
    color: rgba(255, 255, 255, 0.5);
    background: transparent;
    font-size: 16px;
    text-transform: capitalize;
    border: 2px solid #fff;
    border-radius: 50px;
    transition: all 0.3s ease 0s;
}

.panel .panel-heading .btn {
    color: rgba(255, 255, 255, 0.5);
    background: transparent;
    font-size: 16px;
    text-transform: capitalize;
    border: 2px solid #fff;
    border-radius: 50px;
    transition: all 0.3s ease 0s;
}

.panel .panel-heading .btn:hover {
    color: #000;
    text-shadow: 3px 3px rgba(255, 255, 255, 0.2);
}

.panel .panel-heading .btn-rj:hover {
    color: #000;
    text-shadow: 3px 3px rgba(255, 255, 255, 0.2);
}

.panel .panel-heading .form-control {
    color: #000;
    background-color: transparent;
    width: 35%;
    height: 40px;
    border: 2px solid #000;
    border-radius: 20px;
    display: inline-block;
    transition: all 0.3s ease 0s;
}

.panel .panel-heading .form-control:focus {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: none;
    outline: none;
}

.panel .panel-heading .form-control::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 500;
}

.panel .panel-body {
    padding: 0;
}

.panel .panel-body .table thead tr th {
    color: #fff;
    background-color: #0044a9;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 12px;
    border: none;
    text-shadow: 1px 1px 2px #221d1d;
}

.panel .panel-body .table tbody tr td {
    color: #000;
    /* background-color: #000; */
    font-size: 15px;
    padding: 10px 12px;
    vertical-align: middle;
    border: none;
}

.panel .panel-body .table tbody tr:nth-child(even) {
    background-color: #f6fff9;
}

.panel .panel-body .table tbody .action-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.panel .panel-body .table tbody .action-list li {
    display: inline-block;
    margin: 0 0px;
}

.panel .panel-body .table tbody .action-list li a {
    color: #000;
    font-size: 15px;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
}

.panel .panel-body .table tbody .action-list li a:hover {
    text-shadow: 3px 3px 0 rgba(255, 255, 255, 0.3);
}

.panel .panel-body .table tbody .action-list li a:before,
.panel .panel-body .table tbody .action-list li a:after {
    content: attr(data-tip);
    color: #000;
    background-color: #111;
    font-size: 12px;
    padding: 5px 7px;
    border-radius: 4px;
    text-transform: capitalize;
    display: none;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -32px;
    transition: all 0.3s ease 0s;
}

.panel .panel-body .table tbody .action-list li a:after {
    content: '';
    height: 15px;
    width: 15px;
    padding: 0;
    border-radius: 0;
    transform: translateX(-50%) rotate(45deg);
    top: -18px;
    z-index: -1;
}

.panel .panel-body .table tbody .action-list li a:hover:before,
.panel .panel-body .table tbody .action-list li a:hover:after {
    display: block;
}

.panel .panel-footer {
    color: #000;
    background-color: transparent;
    padding: 15px;
    border: none;
}

.panel .panel-footer .col {
    line-height: 35px;
}

.pagination {
    margin: 0;
}

.pagination li a {
    color: #000;
    background-color: transparent;
    border: 2px solid transparent;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 31px;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0 3px;
    border-radius: 50px;
    transition: all 0.3s ease 0s;
}

.pagination li a:hover {
    color: #000;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.pagination li a:focus,
.pagination li.active a,
.pagination li.active a:hover {
    color: #000;
    background-color: transparent;
    border-color: #000;
}

.pagination li:first-child a,
.pagination li:last-child a {
    border-radius: 50%;
}

@media only screen and (max-width:767px) {
    .panel .panel-heading .title {
        text-align: center;
        margin: 0 0 10px;
    }
    .panel .panel-heading .btn_group {
        text-align: center;
    }
}

.td {
    background-color: #ccc!important;
}

.col-escolas {
    background-color: #0044a9!important;
    color: white;
    text-shadow: 1px 1px 2px #221d1d;
}

h1 {
    text-align: center;
}

th.col-escolas {
    color: white!important;
}

.header-escolas {
    color: #000;
    font-weight: 500;
}

/* div.table-responsive.panel-body {
    display: block !important;
} */
div.title-quesito-yellow {
    display: block !important;
}